import React from 'react'
import { graphql } from 'gatsby'

import Layout from '@components/layout'

import CardList from '@components/post/card-list'
import BannerList from '@components/banner/list'
import LightMasthead from '@components/banner/light-masthead'

export default ({
  data: {
    page: {
      html,
      frontmatter: { keywords, title, masthead },
    },
    latestPosts,
  },
  location,
}) => {
  return (
    <Layout title={title} keywords={keywords} pathname={location.pathname}>
      <BannerList>
        {masthead && masthead.show && (
          <LightMasthead
            backgroundImage={masthead.backgroundImage}
            backgroundColor={masthead.colors.background}
            textColor={masthead.colors.text}
            markerColor={masthead.colors.marker}
            actionColors={masthead.colors.action}
            title={masthead.title}
            subtitle={masthead.subtitle}
            actionLabel={masthead.action.label}
            actionTarget={masthead.action.target}
            actionDisplay={masthead.action.display}
          >
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </LightMasthead>
        )}
        <section>
          <CardList items={latestPosts.edges} />
        </section>
      </BannerList>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        keywords
      }
      ...LightMastheadFragment
    }
    latestPosts: allMarkdownRemark(
      limit: 6
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { published: { eq: true } }
        fields: { slug: { regex: "//(posts|case-studies)/.+/" } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          ...CardFragment
        }
      }
    }
  }
`
