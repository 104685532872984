import React from 'react'

import { graphql } from 'gatsby'

import styled from 'styled-components'

import Card from '@components/post/card'

const CardList = ({
  className,

  items,
}) => {
  return (
    <ol className={className}>
      {items.map((el, i) => {
        const {
          fields: { slug },
          frontmatter: { date, previewImage, summary, tags, title },
        } = el.node
        return (
          <li key={i}>
            <Card
              date={date}
              image={previewImage}
              tags={tags}
              target={slug}
              text={summary}
              title={title}
            />
          </li>
        )
      })}
    </ol>
  )
}

export const query = graphql`
  fragment CardFragment on MarkdownRemark {
    frontmatter {
      date
      summary
      tags
      title
      previewImage {
        childImageSharp {
          fluid(
            maxWidth: 384 #duotone: { highlight: "#ff5583", shadow: "#1e1e1e", opacity: 50}
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

export default styled(CardList)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-top: 2%;

  > li {
    width: 100%;
    background: none;

    ${props => props.theme.media.md`
      width: 49%;

      &:nth-child(2n+1) {
        margin-right: 2%;
      }
      &:nth-child(2n+2) {
        margin-right: 0;
      }
    `}

    ${props => props.theme.media.lg`
      width: 32%;

      &:nth-child(3n+1),
      &:nth-child(3n+2)
      {
        margin-right: 2%;
      }
      &:nth-child(3n+3) {
        margin-right: 0;
      }
    `}

    margin-top: 2%;
    margin-bottom: 2%;

    ${props => props.theme.media.md`
      margin-top: 0;
    `}
  }
`
