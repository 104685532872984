import React from 'react'

import Img from 'gatsby-image'
import { Link } from 'gatsby'
import styled from 'styled-components'

import TagList from '@components/post/tag-list'

const Card = ({
  className,

  date,
  image,
  tags,
  target,
  text,
  title,
}) => {
  return (
    <div className={className}>
      {image && (
        <Link to={target}>
          <Img fluid={image.childImageSharp.fluid} />
        </Link>
      )}
      <Link to={target}>
        <h2>{title}</h2>
      </Link>
      {text && <p>{text}</p>}
      <TagList date={date} tags={tags} />
    </div>
  )
}

export default styled(Card)`
  width: 100%;
  margin-bottom: 4%;
  height: 100%;

  a {
    color: ${props => props.theme.colors.foreground('main')};
    text-decoration: none;
    &:hover {
      color: ${props => props.theme.colors.foreground('main')};
      text-decoration: underline;
    }

    > div {
      height: ${props => props.theme.dimen.card.image.height};
    }

    h2 {
      font-size: ${props => props.theme.font._.h2.size};
      line-height: ${props => props.theme.font._.h2.lineHeight};

      padding-top: ${props => props.theme.dimen.articleTitle.top};
      padding-bottom: ${props => props.theme.dimen.articleTitle.bottom};

      ${props => props.theme.media.sm`
        font-size: ${props => props.theme.font.sm.h2.size};
        line-height: ${props => props.theme.font.sm.h2.lineHeight};
      `}

      ${props => props.theme.media.md`
        font-size: ${props => props.theme.font.md.h2.size};
        line-height: ${props => props.theme.font.md.h2.lineHeight};
      `}

      font-weight: ${props => props.theme.fontWeights.strong};
    }
  }

  p {
    font-size: ${props => props.theme.font._.p.size};
    line-height: ${props => props.theme.font._.p.lineHeight};

    ${props => props.theme.media.sm`
      font-size: ${props => props.theme.font.sm.p.size};
      line-height: ${props => props.theme.font.sm.p.lineHeight};
    `}

    ${props => props.theme.media.md`
      font-size: ${props => props.theme.font.md.p.size};
      line-height: ${props => props.theme.font.md.p.lineHeight};
    `}

    padding-bottom: ${props => props.theme.dimen.articlePreamble.bottom};
  }
`
